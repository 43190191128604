import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

import loginReducer from './LoginReducers';
import logoutReducer from './LogoutReducers';
import cookieReducer from './CookieReducer';

const appReducer = combineReducers({
  loginReducer,
  logoutReducer,
  cookieReducer,
});

const rootReducer = (state, action) => {
  let s = state;
  if (action.type === 'USER_LOGOUT_SUCCESS') {
    // empty state
    s = {};
    // save cookie accept
    s.cookieReducer = state.cookieReducer;
    // wipe history
    Object.keys(state).forEach((key) => {
      // save cookie accept
      if (key !== 'cookieReducer') {
        console.log('deleting: ', key);
        storage.removeItem(`persist:${key}`);
      }
    });
  }
  return appReducer(s, action);
};

export default rootReducer;
