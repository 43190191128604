import loginConstants from '../constants/LoginConstants';

function loginReducer(state = {}, action) {
  switch (action.type) {
    case loginConstants.LOGIN_REQUEST:
      return {
        status: 'requested',
      };
    case loginConstants.LOGIN_SUCCESS:
      return {
        token: action.token,
        user: action.user,
        status: 'success',
      };
    case loginConstants.LOGIN_FAILURE:
      return {
        error: action.error,
        status: 'error',
      };
    case loginConstants.EMAIL_VERIFIED:
      const { user } = state;
      const { userInfo } = user;
      return {
        ...state,
        user: {
          ...user,
          userInfo: {
            ...userInfo,
            verified: action.payload,
          },
        },
      };
    default:
      return state;
  }
}

export default loginReducer;
