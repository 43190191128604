import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import { PersistGate } from 'redux-persist/integration/react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './Theme';

import rootReducer from './reducers/RootReducer';
import App from './App';

// import registerServiceWorker from './registerServiceWorker';

const history = createBrowserHistory();

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  connectRouter(history)(persistedReducer),
  composeWithDevTools(applyMiddleware(
    thunk,
    routerMiddleware(history),
  )),
);

const persistor = persistStore(store);

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App history={history} />
      </PersistGate>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById('app'),
);

// turn off caching!!!
// registerServiceWorker();
