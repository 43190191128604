import React from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Helmet } from 'react-helmet';
import Routes from './Routes';

function App(props) {
  const { history } = props;
  return (
    <div className="App">
      <Helmet>
        <title>Nia | Dance Fitness for the Mind, Body and Soul</title>
        <meta name="description" content="Nia is a holistic movement & wellness practice. Our dance fitness classes and trainings emphasize somatic movement for the mind, body, and soul." />
        <meta name="keywords" content="dance, fitness, classes, somatic, movement" />
      </Helmet>
      <CssBaseline />
      <ConnectedRouter history={history}>
        <Routes />
      </ConnectedRouter>
    </div>
  );
}

App.propTypes = {
  history: PropTypes.object.isRequired,
};

export default App;
