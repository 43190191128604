import logoutConstants from '../constants/LogoutConstants';

function logoutReducer(state = {}, action) {
  switch (action.type) {
    case logoutConstants.LOGOUT_REQUEST:
      return {
        status: 'requested',
      };
    case logoutConstants.LOGOUT_SUCCESS:
      return {
        status: 'success',
      };
    case logoutConstants.LOGOUT_FAILURE:
      return {
        status: 'error',
      };
    default:
      return state;
  }
}

export default logoutReducer;
