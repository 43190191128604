import { createMuiTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const theme = createMuiTheme({
// GREEN: #AFBD47 | rgb(175,189,71)
// RED: #ED403F | rgb(237,64,63)
// BLUE: #8196E5 | rgb(129,150,229)
  overrides: {
    MuiStepIcon: {
      root: {
        '&$completed': {
          color: 'grey',
        },
      },
    },
  },
  palette: {
    primary: {
      // main: '#ED403F',
      main: '#fff',
      // main: '#d74f4f',
      // main: '#D55052', // "actual nia red according to chris, i say bullshit"
    },
    secondary: grey,
    // secondary: {
    //   main: '#8196E5',
    // },
    blue: '#8196E5',
    green: '#AFBD47',
    red: '#ED403F',
  },
  typography: {
    fontFamily: 'Montserrat',
    useNextVariants: true,
    body1: {
      fontFamily: 'Ingra',
    },
    body2: {
      fontFamily: 'Ingra',
    },
  },
});

export default theme;
