import cookieConstants from '../constants/CookieConstants';

function cookieReducer(state = {}, action) {
  switch (action.type) {
    case cookieConstants.ACCEPTED:
      return {
        accepted: true,
      };
    default:
      return state;
  }
}

export default cookieReducer;
