/* eslint-disable react/forbid-prop-types */
import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import {
  Route,
  BrowserRouter,
  Switch,
  Redirect,
} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const CookiesAccept = lazy(() => import('./components/CookiesAccept'));
const ScrollToTop = lazy(() => import('./shared/ScrollToTop'));

const Homepage = lazy(() => import('./components/homepage/HomePage'));
const Register = lazy(() => import('./components/register/RegisterPage'));
const Login = lazy(() => import('./components/login/LoginPage'));
const Logout = lazy(() => import('./components/logout/LogoutPage'));
const Account = lazy(() => import('./components/account/AccountPage'));
const Profile = lazy(() => import('./components/indv_pages/ProfilePage'));
const ClassFinder = lazy(() => import('./components/finder/class/ClassFinder'));
const TrainingFinder = lazy(() => import('./components/finder/training/TrainingFinder'));
const TeacherFinder = lazy(() => import('./components/finder/teacher/Finder'));
const TrainerFinder = lazy(() => import('./components/finder/trainer/Finder'));
const Class = lazy(() => import('./components/indv_pages/ClassPage'));
const Location = lazy(() => import('./components/indv_pages/LocationPage'));
const PrivacyPage = lazy(() => import('./components/indv_pages/PrivacyPage'));
const TermsPage = lazy(() => import('./components/indv_pages/TermsPage'));
const CCPAPage = lazy(() => import('./components/indv_pages/CCPAPage'));
const ContactPage = lazy(() => import('./components/indv_pages/ContactPage'));
const AboutMembershipPage = lazy(() => import('./components/indv_pages/AboutMembershipPage'));
const SpecialtyTrainings = lazy(() => import('./components/trainings/specialtytrainings/SpecialtyTrainingsPage'));
const BodyLifeTrainings = lazy(() => import('./components/trainings/bodylifetrainings/BodyLifeTrainingsPage'));
const Shop = lazy(() => import('./components/shop/ShopPage'));
const Activate = lazy(() => import('./components/indv_pages/ActivatePage'));
const RecoverAccount = lazy(() => import('./components/account_recovery/RecoverAccountPage'));
const ResetPassword = lazy(() => import('./components/account_recovery/ResetPasswordPage'));
const NotFoundPage = lazy(() => import('./components/indv_pages/NotFoundPage'));
const Join = lazy(() => import('./components/indv_pages/JoinPage'));
const AboutEvents = lazy(() => import('./components/indv_pages/AboutEventsPage'));
const AboutClasses = lazy(() => import('./components/indv_pages/AboutClassesPage'));
const AboutTrainings = lazy(() => import('./components/indv_pages/AboutTrainingsPage'));
const DebbieRosasEvents = lazy(() => import('./components/events/DebbieRosasEventsPage'));
const PressRoom = lazy(() => import('./components/indv_pages/PressRoom'));
const AboutNiaPage = lazy(() => import('./components/indv_pages/AboutNiaPage'));
const ClassTypesPage = lazy(() => import('./components/classes/ClassTypesPage'));
const TeachPage = lazy(() => import('./components/indv_pages/TeachPage'));
const DEICommitmentPage = lazy(() => import('./components/indv_pages/DEICommitmentsPage'));
const SignupThankYou = lazy(() => import('./components/indv_pages/EmailSignupThankYou'));
const MasqueradePage = lazy(() => import('./components/masquerade/MasqueradePage'));
const Blog = lazy(() => import('./components/blog/Blog'));
const BlogPostDetail = lazy(() => import('./components/blog/BlogPostDetail'));
const AboutBeltsPage = lazy(() => import('./components/indv_pages/AboutBeltsPage'));
const FAQPage = lazy(() => import('./components/indv_pages/FAQPage'));
const NiaDayGuidePage = lazy(() => import('./components/leadgen_pages/NiaDayGuidePage'));
const NiaDayGuideThankYou = lazy(() => import('./components/leadgen_pages/NiaDayGuideThankYou'));
const HolisticFitnessPage = lazy(() => import('./components/leadgen_pages/HolisticFitnessPage'));
const HolisticFitnessThankYouPage = lazy(() => import('./components/leadgen_pages/HolisticFitnessThankYouPage'));
const BarefootDayPage = lazy(() => import('./components/leadgen_pages/BarefootDayPage'));
const BarefootDayThankYou = lazy(() => import('./components/leadgen_pages/BarefootDayThankYou'));
const SalePage = lazy(() => import('./components/indv_pages/SalePage'));
const TestimonialsPage = lazy(() => import('./components/indv_pages/TestimonialsPage'));
// const MaintenanceMode = lazy(() => import('./components/MaintenanceMode'));

const styles = () => ({
  root: {
    display: 'flex',
  },
  loading: {
    height: '100vh',
    width: '100vw',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
});

function Routes(props) {
  const { classes } = props;
  return (
    <BrowserRouter>
      <Suspense fallback={<div className={classes.loading}><CircularProgress color="secondary" /></div>}>
        <ScrollToTop>
          <div className={classes.root}>
            <Switch>
              <Route exact path="/" component={Homepage} />
              {/* REDIRECTS */}
              <Route path="/geofind"><Redirect to="/finder/class" /></Route>
              <Route exact path="/finder/class/inperson"><Redirect to="/finder/class?attendance_type=inperson" /></Route>
              <Route exact path="/finder/class/online"><Redirect to="/finder/class?attendance_type=online" /></Route>
              <Route exact path="/finder/training/inperson"><Redirect to="/finder/training?attendance_type=inperson" /></Route>
              <Route exact path="/finder/training/online"><Redirect to="/finder/training?attendance_type=online" /></Route>
              {/* USER */}
              <Route exact path="/register" component={Register} />
              <Route exact path="/activate/:id/:token" component={Activate} />
              <Route exact path="/account_recovery" component={RecoverAccount} />
              <Route exact path="/password_reset/:id/:token" component={ResetPassword} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/logout" component={Logout} />
              {/* ACCOUNT */}
              <Route path="/account" component={Account} />
              {/* FINDER */}
              <Route exact path="/finder/class/:query?" component={ClassFinder} />
              <Route exact path="/finder/training/:query?" component={TrainingFinder} />
              <Route exact path="/finder/teacher/" component={TeacherFinder} />
              <Route exact path="/finder/trainer/" component={TrainerFinder} />
              {/* BLOG */}
              <Route exact path="/blog" component={Blog} />
              <Route exact path="/blog/:idOrSlug" component={BlogPostDetail} />
              {/* INDV PAGES */}
              <Route exact path="/about-nia" component={AboutNiaPage} />
              <Route exact path="/user/:userNameOrId" component={Profile} />
              <Route exact path="/class/:id" component={Class} />
              <Route exact path="/contact" component={ContactPage} />
              <Route exact path="/terms" component={TermsPage} />
              <Route exact path="/privacy" component={PrivacyPage} />
              <Route exact path="/ccpa" component={CCPAPage} />
              <Route exact path="/membership" component={AboutMembershipPage} />
              <Route exact path="/location/:id" component={Location} />
              <Route exact path="/specialtytrainings/:trainingType?" component={SpecialtyTrainings} />
              <Route exact path="/trainings/:trainingType?" component={BodyLifeTrainings} />
              <Route exact path="/join" component={Join} />
              <Route exact path="/class-intro" component={AboutClasses} />
              <Route exact path="/classes/:classType?" component={ClassTypesPage} />
              <Route exact path="/training-intro" component={AboutTrainings} />
              <Route exact path="/event-intro" component={AboutEvents} />
              <Route exact path="/debbie-rosas-events" component={DebbieRosasEvents} />
              <Route exact path="/press-room" component={PressRoom} />
              <Route exact path="/teach" component={TeachPage} />
              <Route exact path="/dei" component={DEICommitmentPage} />
              <Route exact path="/masquerade/:username/:token" component={MasqueradePage} />
              <Route exact path="/signup_thankyou" component={SignupThankYou} />
              <Route exact path="/belt-tutorials" component={AboutBeltsPage} />
              <Route exact path="/faq" component={FAQPage} />
              <Route exact path="/sale" component={SalePage} />
              <Route exact path="/testimonials" component={TestimonialsPage} />
              {/* LEAD GEN */}
              <Route exact path="/nia-day-guide" component={NiaDayGuidePage} />
              <Route exact path="/nia-day-thankyou" component={NiaDayGuideThankYou} />
              <Route exact path="/holistic-fitness-report" component={HolisticFitnessPage} />
              <Route exact path="/holistic-fitness-thankyou" component={HolisticFitnessThankYouPage} />
              <Route exact path="/barefoot-day" component={BarefootDayPage} />
              <Route exact path="/barefoot-day-thankyou" component={BarefootDayThankYou} />
              {/* SHOP */}
              <Route path="/shop" component={Shop} />
              {/* NOT FOUND (must be last) */}
              <Route path="/404" component={NotFoundPage} />
              <Route path="*"><Redirect to="/404" /></Route>
            </Switch>
          </div>
        </ScrollToTop>
        <CookiesAccept />
      </Suspense>
    </BrowserRouter>
  );
}

//uncomment for maintenance mode
// function Routes(props) {
//   const { classes } = props;
//   return (
//     <BrowserRouter>
//       <Suspense fallback={<div className={classes.loading}><CircularProgress color="secondary" /></div>}>
//         <ScrollToTop>
//           <div className={classes.root}>
//             <Switch>
//               <Route path="*" component={MaintenanceMode} />
//             </Switch>
//           </div>
//         </ScrollToTop>
//         <CookiesAccept />
//       </Suspense>
//     </BrowserRouter>
//   );
// }

Routes.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Routes);
